<template>
  <div>
    <section>
      <button
        class="uk-button uk-button-default uk-border-rounded uk-margin-right"
        style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
        @click="saveData"
      >Save</button>
      <button
          class="uk-button uk-button-default uk-border-rounded uk-margin-right"
          style="border-color: #0ABAB5; color: #0ABAB5"
          @click="$emit('action', false)"
      >Cancel</button>
    </section>

    <!-- Required Document -->
    <section class="uk-margin">
      <div
        v-for="(detail, dKey) in details"
        :key="dKey"
        class="uk-grid-collapse"
        style="padding: .2rem 0"
        uk-grid
      >
        <div class="uk-width-1-2">{{ detail.label }}</div>

        <div class="uk-width-1-2">
          <div class="uk-grid-collapse" uk-grid>
            <div class="uk-width-expand">
              <!-- IF FILE EXIST -->
              <div v-if="form[detail.value]" class="uk-form-controls">
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between uk-flex-middle"
                  style="
                    height: 40px;
                    padding: 0 30px;
                    border-radius: 5px;
                    border: 1px solid #0abab5;
                    color: #0abab5;
                  "
                >
                  <span
                    style="
                      width: 90%;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    "
                    :title="form[detail.value].file_path"
                  >
                    {{ form[detail.value].file_path }}
                  </span>
                  <img
                    :src="`${images}/cross.svg`"
                    alt="close icon"
                    width="15"
                    heigth="15"
                    style="cursor: pointer;"
                    @click="deleteFile(detail.value)"
                  />
                </div>
              </div>
              <!-- IF NO FILE -->
              <div v-else class="uk-form-controls">
                <button
                  class="uk-button uk-button-default uk-width-1-1"
                  style="
                    border-radius: 5px;
                    border: 1px solid #0abab5;
                    color: #0abab5;
                  "
                  type="button"
                  @click="triggerUpload(detail)"
                >
                  <img :src="`${images}/shift-download.svg`" alt="" width="25">
                  Upload File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Additional Document -->
    <section class="uk-margin">
      <p class="uk-text-bold uk-margin-remove">Additional Document(s)</p>
      <div
        v-for="(detail, dKey) in additionalDoc"
        :key="dKey"
        class="uk-grid-collapse"
        style="padding: .2rem 0"
        uk-grid
      >
        <div class="uk-width-1-2">{{ detail.doc_name }}</div>

        <div class="uk-width-1-2">
          <div class="uk-grid-collapse" uk-grid>
            <div class="uk-width-expand">
              <!-- IF FILE EXIST -->
              <div v-if="findAddDoc(detail)" class="uk-form-controls">
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between uk-flex-middle"
                  style="
                    height: 40px;
                    padding: 0 30px;
                    border-radius: 5px;
                    border: 1px solid #0abab5;
                    color: #0abab5;
                  "
                >
                  <span
                    style="
                      width: 90%;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    "
                    :title="findAddDoc(detail).name"
                  >
                    {{ findAddDoc(detail).name }}
                  </span>
                  <img
                    :src="`${images}/cross.svg`"
                    alt="close icon"
                    width="15"
                    heigth="15"
                    style="cursor: pointer;"
                    @click="deleteAdditionalFile(findAddDoc(detail)._id)"
                  />
                </div>
              </div>
              <!-- IF NO FILE -->
              <div v-else class="uk-form-controls">
                <button
                  class="uk-button uk-button-default uk-width-1-1"
                  style="
                    border-radius: 5px;
                    border: 1px solid #0abab5;
                    color: #0abab5;
                  "
                  type="button"
                  @click="triggerUploadADoc(detail)"
                >
                  <img :src="`${images}/shift-download.svg`" alt="" width="25">
                  Upload File
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Upload Input -->
    <input ref="upload" style="display: none" type="file" @change="uploadFileVerification" />
    <input ref="uploadADoc" style="display: none" type="file" @change="uploadFileVerificationADoc" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationSuccess, notificationDangerCustom } from '@/utils/notification';
import {PREFIX_IMAGE} from '@/utils/constant';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false,
            uploadDocType: null,
            isUploadComplete: false,
            details: [
                { label: 'Foto KTP', value: 'other_document_ktp', api: "KTP" },
                { label: 'Foto Kartu Keluarga', value: 'other_document_kk', api: "KK" },
                { label: 'Foto Ijazah Terakhir', value: 'other_document_ijazah', api: "Ijazah" },
                { label: 'Foto Buku tabungan (halaman nomor rekening)', value: 'other_document_bank_account', api: "Bank Account" },
                { label: 'Pas Photo', value: 'other_document_identification_photo', api: "Identification Photo" },
                { label: 'Foto SKCK', value: 'other_document_skck', api: "SKCK" },
                { label: 'Foto Kartu BPJS Kesehatan', value: 'other_document_bpjs_kes_photo', api: "BPJS Kes" },
                { label: 'Kartu BPJS Ketenagakerjaan', value: 'other_document_bpjs_tk_photo', api: "BPJS TK" },
                { label: 'CV', value: 'other_document_cv', api: "CV" },
                { label: 'Foto NPWP', value: 'other_document_npwp', api: "NPWP" },
                { label: 'Foto Paklaring', value: 'other_document_paklaring', api: "Paklaring" },
                { label: 'Personal Guarantee', value: 'other_document_personal_guarantee', api: "Personal Guarantee" },
            ],
            form: { ...this.$props.data },
            additionalDoc: [],
            fileUpload: [],
            errorsD: {},
        };
    },
    mounted() {
        this.additionalDoc = this.form.other_document.filter(d => d.doc_name);
    },
    methods: {
        ...mapActions({
            uploadDocument: 'other_document/uploadDocument',
            patchDocument: 'mitra/patchDocument',
        }),

        triggerUpload(data) {
            this.uploadDocType = data;
            this.$refs.upload.click();
        },

        triggerUploadADoc(data) {
            this.uploadDocType = data;
            this.$refs.uploadADoc.click();
        },

        findAddDoc(data) {
            const checkData = this.form.other_document.findIndex(file => {
                return file.doc_code === data.doc_code;
            });
            if (checkData > -1) {
                return this.form.other_document[checkData];
            } else {
                return false;
            }
        },

        deleteFile(data) {
            this.form[data] = null;

            const checkData = this.fileUpload.findIndex(file => {
                return file.detail.value === data;
            });
            if (checkData > -1) {
                this.fileUpload.splice(checkData, 1);
            }
        },

        deleteAdditionalFile(id) {
            const checkDataForm = this.form.other_document.findIndex(file => {
                return file._id === id;
            });
            if (checkDataForm > -1) {
                this.form.other_document.splice(checkDataForm, 1);
            }

            const checkData = this.fileUpload.findIndex(file => {
                return file.detail.value === checkDataForm;
            });
            if (checkData > -1) {
                this.fileUpload.splice(checkData, 1);
            }
        },

        async uploadFileVerification(e) {
            if (e.target.files) {
                if (this.checkFile(e.target.files[0].size, e.target.files[0].type)) {
                    const checkData = this.fileUpload.findIndex(file => {
                        return file.detail.value === this.uploadDocType.value;
                    });

                    const data = {
                        detail: this.uploadDocType,
                        file: e.target.files[0],
                    };
                    if (checkData > -1) {
                        this.fileUpload[checkData] = data;
                    } else {
                        this.fileUpload.push(data);
                    }

                    this.form[this.uploadDocType.value] = { file_path: e.target.files[0].name };
                    this.$refs.upload.value = null;
                }
            }
        },

        async uploadFileVerificationADoc(e) {
            if (e.target.files) {
                if (this.checkFile(e.target.files[0].size, e.target.files[0].type)) {
                    const checkData = this.fileUpload.findIndex(file => {
                        return file.detail.value === this.uploadDocType.doc_code;
                    });

                    const data = {
                        detail: {
                            api: 'Other',
                            label: this.uploadDocType.doc_name,
                            value: this.uploadDocType.doc_code,
                        },
                        file: e.target.files[0],
                    };
                    if (checkData > -1) {
                        this.fileUpload[checkData] = data;
                    } else {
                        this.fileUpload.push(data);
                    }

                    this.form.other_document.push({ name: e.target.files[0].name, doc_code: this.uploadDocType.doc_code });
                    this.$refs.upload.value = null;
                }
            }
        },

        checkFile(fileSize, fileExt) {
            if (fileSize / 1024 / 1024 > 10) {
                notificationDangerCustom('File terlalu besar, maksimal file berukuran 10 MB');
                this.$refs.upload.value = null;
                return false;
            }

            const extension = ["png", "jpg", "jpeg", "pdf"];
            const splitImg = fileExt.split("/");
            let imgMime = splitImg[splitImg.length - 1];

            if (!extension.includes(imgMime)) {
                notificationDangerCustom('Ekstensi file tidak dikenali');
                this.$refs.upload.value = null;
                return false;
            }

            return true;
        },

        async uploadFile () {
            for (let index = 0; index < this.fileUpload.length; index++) {
                let formData = new FormData();
                formData.append('file', this.fileUpload[index].file);
                let query = this.fileUpload[index].detail.api === 'Other'
                    ? `?doc_code=${this.fileUpload[index].detail.value}`
                    : '';

                const result = await this.uploadDocument({
                    api: this.fileUpload[index].detail.api,
                    user_id: `${this.$route.params.mitra_id}${query}`,
                    payload: formData
                });

                if (result.status === 'OK') {
                    notificationSuccess(`Upload successful`);
                    this.form[this.fileUpload[index].detail.value] = result.result.document_url;
                    this.isUploadComplete = true;
                } else {
                    notificationDangerCustom('Failed to upload!');
                    this.isUploadComplete = false;
                    break;
                }
            }
        },

        async saveData() {
            this.isLoading = true;
            this.isUploadComplete = true;

            await this.uploadFile();

            if (!this.isUploadComplete)
                return false;

            const nForm = {
                "other_document": !this.form.other_document || this.form.other_document === "" || this.form.other_document.length < 1
                    ? [] : this.form.other_document.map(d => ({ doc_id: d.doc_id, filename: d.document_url.file_path })),
            };

            for (const key of Object.keys(this.form)) {
                if (key === "other_document") continue;
                if (this.form[key] && this.form[key] !== "") {
                    nForm[key] = {};
                    nForm[key].document = this.form[key].file_path;
                    if (this.form[key].is_valid !== undefined && this.form[key].is_valid !== null) nForm[key].is_valid = this.form[key].is_valid;
                    if (this.form[key].notes !== undefined && this.form[key].notes !== null) nForm[key].notes = this.form[key].notes;
                } else {
                    nForm[key] = null;
                }
            }

            await this.patchDocument({ user_id: this.$route.params.mitra_id, payload: nForm });
            notificationSuccess('Update saved!');

            this.isLoading = false;
            this.$emit('action', false);
        }
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
