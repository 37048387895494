<template>
  <div>
    <section>
      <button
        v-if="$can('EDIT', 'worker-mitra')"
        class="uk-button uk-button-default uk-border-rounded uk-margin-right"
        style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
        @click="$emit('action', true)"
      >Edit</button>
    </section>

    <!-- Required Document -->
    <section class="uk-margin">
      <div
        v-for="(detail, dKey) in details"
        :key="dKey"
        class="uk-grid-collapse"
        style="padding: .2rem 0"
        uk-grid
      >
        <div class="uk-width-1-2">{{ detail.label }}</div>

        <div class="uk-width-1-2">
          <div class="uk-grid-collapse" uk-grid>
            <div class="uk-width-auto">:&nbsp;</div>
            <div class="uk-width-expand">
              <span
                v-if="data[detail.value]"
                style="text-decoration: underline; color: #0ABAB5; cursor: pointer;"
                @click="openImage({ ...detail, file_url: data[detail.value].file_url})"
              >Lihat Lampiran</span>
              <span v-else style="text-decoration: underline;">Lihat Lampiran</span>
              <span v-if="data[detail.value]" class="uk-margin-left">
                <span
                  v-if="!data[detail.value].is_checked"
                  style="text-decoration: underline; color: #0275D8; cursor: pointer;"
                  @click="validateDocument(detail.value)"
                >
                  <img :src="`${images}/view-board.svg`" alt="" style="width:20px" />
                  Validasi Dokumen
                </span>
                <span
                  v-else-if="data[detail.value].is_valid"
                  style="text-decoration: underline; color: #5FD855;"
                >
                  <img :src="`${images}/verif-green.svg`" alt="" style="width:20px" />
                  Dokumen Valid
                </span>
                <span v-else style="text-decoration: underline; color: #FFC737;">
                  <img :src="`${images}/warning-small-yellow.svg`" alt="" style="width:20px" />
                  Dokumen Tidak Valid
                </span>
              </span>
              <span v-else class="uk-margin-left" >
                <img :src="`${images}/view-board.svg`" alt="" style="width:20px" />
                Validasi Dokumen
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Additional Document -->
    <section v-if="data" class="uk-margin">
      <p class="uk-text-bold uk-margin-remove">Additional Document(s)</p>
      <div
        v-for="(detail, dKey) in data.other_document.filter(d => d.doc_name)"
        :key="dKey"
        class="uk-grid-collapse"
        style="padding: .2rem 0"
        uk-grid
      >
        <div class="uk-width-1-2">{{ detail.doc_name }}</div>

        <div class="uk-width-1-2">
          <div class="uk-grid-collapse" uk-grid>
            <div class="uk-width-auto">:&nbsp;</div>
            <div class="uk-width-expand">
              <span
                style="text-decoration: underline; color: #0ABAB5; cursor: pointer;"
                @click="openImage({ ...detail, file_url: detail.document_url.file_url})"
              >Lihat Lampiran</span>
              <!-- <span class="uk-margin-left">
                <span
                  v-if="!detail.is_checked"
                  style="text-decoration: underline; color: #0275D8; cursor: pointer;"
                  @click="validateDocument('other_document', detail)"
                >
                  <img :src="`${images}/view-board.svg`" alt="" style="width:25px" />
                  Validasi Dokumen
                </span>
                <span
                  v-else-if="detail.is_valid"
                  style="text-decoration: underline; color: #5FD855;"
                >
                  <img :src="`${images}/verif-green.svg`" alt="" style="width:25px" />
                  Dokumen Valid
                </span>
                <span
                  v-else
                  style="text-decoration: underline; color: #FFC737;"
                >
                  <img :src="`${images}/warning-small-yellow.svg`" alt="" style="width:25px" />
                  Dokumen Tidak Valid
                </span>
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Upload Input -->
    <input ref="upload" style="display: none" type="file" @change="previewImage" />

    <!-- Modal Photo -->
    <section>
      <div id="modal-center" class="uk-flex-top" uk-modal>
        <div class="uk-modal-dialog">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <div class="uk-modal-header uk-text-center">
            <h2 class="uk-modal-title">Lampiran</h2>
          </div>
          <div class="uk-modal-body">
            <img :src="detailWorkerImgChecker(imgSrc.file_url, isEdit, newSrc)" alt="lampiran" height="350" style="margin: auto; display: block">
          </div>
          <div class="uk-modal-footer uk-text-center">
            <section v-if="isEdit">
              <button
                class="uk-button uk-button-default"
                type="button"
                style="border-color: #0ABAB5; color: #0ABAB5; margin: 0 1rem;"
                @click="cancelUpload"
              >Cancel</button>
              <button
                class="uk-button uk-button-primary"
                type="button"
                style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff; margin: 0 1rem;"
                @click="uploadFileVerification"
              >Save</button>
            </section>
            <section v-else>
              <button
                class="uk-button uk-button-default"
                type="button"
                style="border-color: #0ABAB5; color: #0ABAB5; margin: 0 1rem;"
                @click="triggerUpload()"
              >Ubah Lampiran</button>
              <button
                class="uk-button uk-button-primary"
                type="button"
                style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff; margin: 0 1rem;"
                @click="open(imgSrc.file_url)"
              >Preview</button>
            </section>
          </div>
        </div>
      </div>
    </section>

    <ValidateDocument
      v-if="docValidating"
      :document="selectedDoc"
      :user-id="$route.params.mitra_id"
      @close="afterValidation()"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationSuccess, notificationDangerCustom } from '@/utils/notification';
import { detailWorkerImgChecker } from '@/utils/helper';
import { PREFIX_IMAGE } from '@/utils/constant';
import formatter from '@/utils/formatter';

export default {
    components: {
        ValidateDocument: () => import('@/components/globals/modals/ValidateDocumentModal.vue'),
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            imgSrc: {},
            oldSrc: {},
            newSrc: {},
            isLoading: false,
            isEdit: false,
            details: [
                { label: 'Foto KTP', value: 'other_document_ktp', api: "KTP" },
                { label: 'Foto Kartu Keluarga', value: 'other_document_kk', api: "KK" },
                { label: 'Foto Ijazah Terakhir', value: 'other_document_ijazah', api: "Ijazah" },
                { label: 'Foto Buku tabungan (halaman nomor rekening)', value: 'other_document_bank_account', api: "Bank Account" },
                { label: 'Pas Photo', value: 'other_document_identification_photo', api: "Identification Photo" },
                { label: 'Foto SKCK', value: 'other_document_skck', api: "SKCK" },
                { label: 'Foto Kartu BPJS Kesehatan', value: 'other_document_bpjs_kes_photo', api: "BPJS Kes" },
                { label: 'Kartu BPJS Ketenagakerjaan', value: 'other_document_bpjs_tk_photo', api: "BPJS TK" },
                { label: 'CV', value: 'other_document_cv', api: "CV" },
                { label: 'Foto NPWP', value: 'other_document_npwp', api: "NPWP" },
                { label: 'Foto Paklaring', value: 'other_document_paklaring', api: "Paklaring" },
                { label: 'Personal Guarantee', value: 'other_document_personal_guarantee', api: "Personal Guarantee" },
            ],
            data: null,
            detailWorkerImgChecker,
            docValidating: false,
            selectedDoc: '',
            additionalDocId: '',
            additionalDocName: '',
        };
    },
    beforeMount() {
        if (window.UIkit.modal("#modal-center")) window.UIkit.modal("#modal-center").$destroy(true);
    },
    async mounted() {
        this.getMitraDocument();
    },
    methods: {
        ...mapActions({
            uploadDocument: 'other_document/uploadDocument',
            getDocument: 'mitra/getDocument',
        }), 

        openImage(data) {
            this.imgSrc = data;
            this.oldSrc = {};
            this.newSrc = {};
            this.isEdit = false;
            window.UIkit.modal("#modal-center").show();
        },

        triggerUpload() {
            this.$refs.upload.click();
        },

        open(url) {
            window.open(formatter.getMyrobinFilePreviewerUrl(url), '_blank').focus();
        },

        previewImage(e) {
            if (e.target.files) {
                if (this.checkFile(e.target.files[0].size, e.target.files[0].type)) {
                    const reader = new FileReader();
                    this.newSrc = e.target.files[0];

                    reader.readAsDataURL(this.newSrc);
                    reader.onload = () => {
                        this.oldSrc = JSON.parse(JSON.stringify(this.imgSrc));
                        this.imgSrc.file_url = reader.result;
                    };

                    this.$refs.upload.value = null;
                    this.isEdit = true;
                }
            }
        },

        checkFile(fileSize, fileExt) {
            if (fileSize / 1024 / 1024 > 10) {
                notificationDangerCustom('File terlalu besar, maksimal file berukuran 10 MB');
                this.$refs.upload.value = null;
                return false;
            }

            const extension = ["png", "jpg", "jpeg", "pdf"];
            const splitImg = fileExt.split("/");
            let imgMime = splitImg[splitImg.length - 1];

            if (!extension.includes(imgMime)) {
                notificationDangerCustom('Ekstensi file tidak dikenali');
                this.$refs.upload.value = null;
                return false;
            }

            return true;
        },

        cancelUpload() {
            this.newSrc = {};
            this.imgSrc = JSON.parse(JSON.stringify(this.oldSrc));
            this.oldSrc = {};
            this.isEdit = false;
        },

        async uploadFileVerification() {
            if (this.newSrc) {
                let formData = new FormData();
                formData.append('file', this.newSrc);
                let query = !this.imgSrc.api
                    ? `?doc_code=${this.imgSrc.doc_code}`
                    : '';

                const result = await this.uploadDocument({
                    api: this.imgSrc.api || 'Other',
                    user_id: `${this.$route.params.mitra_id}${query}`,
                    payload: formData
                });

                if (result.status === 'OK') {
                    notificationSuccess(`Upload successful`);
                    this.data = null;
                    this.getMitraDocument();
                    this.imgSrc.file_url = result.result.document_url.file_url;
                    this.isEdit = false;
                } else {
                    notificationDangerCustom('Failed to upload!');
                }
            }
            await window.UIkit.modal("#modal-center").hide();
        },

        async getMitraDocument() {
            this.isLoading = true;
            const data = await this.getDocument(this.$route.params.mitra_id);

            if (data.result) {
                this.data = data.result.user_cv;
                this.$emit("passData", this.data);
            }

            this.isLoading = false;
        },
        validateDocument(value) {
            this.docValidating = true;
            this.selectedDoc = value;
        },
        async afterValidation() {
            await this.getMitraDocument();
            this.docValidating = false;
            this.selectedDoc = '';
        },
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
